export function getClassName<P extends { className?: string }>(
  props: P,
  initial?: string
): ClassNames {
  let className = classNames([initial]);
  if (props.className) {
    className.push(props.className);
    delete props.className;
  }
  return className as ClassNames;
}

export interface ClassNames extends Array<string> {
  match(args: { [k: string]: boolean | undefined }): void;
}

function classNames(initial: string[] = []): ClassNames {
  let arr = initial;
  (arr as ClassNames).match = args => {
    arr.push(
      Object.keys(args)
        .filter(key => !!args[key])
        .join(" ")
    );
  };

  return arr as ClassNames;
}
