import React, { useState } from "react";
// @ts-ignore: css module
import styles from "./newsletterSignUp.module.scss";
import fetchJsonp from "fetch-jsonp";

function isEmail(email: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

const NewsletterSignUp = () => {
  const [email, setEmail] = useState<string>("");
  const [notiType, setNotiType] = useState();
  const [msg, setMsg] = useState();
  const [submitting, setSubmitting] = useState(false);

  function handleInputOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setEmail(value);
  }

  async function handleFormOnSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!email) {
      setNotiType("error");
      setMsg("Email is required");
      return;
    }

    if (!isEmail(email)) {
      setNotiType("error");
      setMsg("Invalid email address");
      return;
    }

    setMsg("");
    setNotiType("");
    setSubmitting(true);

    const url = `https://sajari.us20.list-manage.com/subscribe/post-json?u=c8f78fce15cf4b6903793cd39&id=4d0543e1f4&c=341024297382749763807_1580878841242&EMAIL=${email}&b_c8f78fce15cf4b6903793cd39_4d0543e1f4=&_=1580876092404`;

    try {
      const response = await fetchJsonp(url, { jsonpCallback: "c" });
      const data = await response.json();
      setMsg(data["msg"]);
      if (data.result !== "success") {
        setNotiType("error");
      } else {
        setNotiType("success");
        setEmail("");
      }
    } catch (err) {
      const msg = (
        <div>
          Error! please email{" "}
          <a href="mailto:support@sajari.com">support@sajari.com</a>
        </div>
      );
      setNotiType("error");
      setMsg(msg);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div>
      <form
        className={`${styles.form} ${submitting ? styles.submitting : ""}`}
        onSubmit={handleFormOnSubmit}
        id="form-register"
        noValidate
      >
        <input
          id="form-register__input"
          className={styles.input}
          type="email"
          name="email"
          autoComplete="email"
          placeholder="Your email address"
          tabIndex={-1}
          value={email}
          onChange={handleInputOnChange}
        />
        <input
          className={styles.button}
          type="submit"
          disabled={submitting}
          value="Sign up"
        />
      </form>
      {notiType && (
        <div
          className={`${styles.response} ${styles[notiType] || ""}`}
          dangerouslySetInnerHTML={{ __html: msg }}
        ></div>
      )}
    </div>
  );
};

export default NewsletterSignUp;
