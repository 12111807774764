import React, { useRef, useEffect, useState, useMemo } from "react";
import { Link, withPrefix } from "gatsby";
import useToggle from "../../hooks/useToggle";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

import styles from "./styles.module.scss";
import { Location } from "@reach/router";
import navigationItems from "../../../docs/site-navigation";
import NavContext from "../../context/NavContext";

// https://css-tricks.com/using-css-transitions-auto-dimensions/
function collapseSection(element: HTMLElement) {
  var sectionHeight = element.scrollHeight;
  var elementTransition = element.style.transition;
  element.style.transition = "";
  requestAnimationFrame(function() {
    element.style.height = sectionHeight + "px";
    element.style.transition = elementTransition;
    requestAnimationFrame(function() {
      element.style.height = 0 + "px";
    });
  });
  element.setAttribute("data-collapsed", "true");
}

function expandSection(element: HTMLElement) {
  var sectionHeight = element.scrollHeight;
  element.style.height = sectionHeight + "px";
  function func(e) {
    element.removeEventListener("transitionend", func);
    element.style.height = "null";
  }
  element.addEventListener("transitionend", func);
}

interface Item {
  path: string;
  external: boolean;
  title: string;
  items?: Item[];
}

const SubLevelMenu = ({
  items,
  on,
  pathname
}: {
  items: Item[];
  on: boolean;
  pathname: string;
}) => {
  const ref = useRef();
  const [hidden, setHidden] = useState(!on);

  useEffect(() => {
    setHidden(false);
  }, []);

  useEffect(() => {
    if (!hidden) {
      if (on) {
        expandSection(ref.current);
      } else {
        collapseSection(ref.current);
      }
    }
  }, [on]);

  useEffect(() => {
    if (!on) {
      collapseSection(ref.current);
    }
  }, []);

  return (
    <ul
      ref={ref}
      className={styles.subLevelMenu + (hidden ? ` ${styles.hidden}` : "")}
      style={{ height: "auto" }}
    >
      {items.map((childItem, index) => (
        <MenuItem item={childItem} pathname={pathname} key={index} />
      ))}
    </ul>
  );
};

const hasActiveLink = (items: Item[] | null, pathname) => {
  if (!items) {
    return false;
  }

  const hasActiveLinkAtFirstLevel = items.some(
    ({ path }) => withPrefix(path) === pathname
  );

  if (hasActiveLinkAtFirstLevel) {
    return true;
  }

  for (let i = 0; i < items.length; i++) {
    const childItems = items[i].items;
    const hasActiveLinkAtChildLevel = hasActiveLink(childItems, pathname);
    if (hasActiveLinkAtChildLevel) {
      return true;
    }
  }

  return false;
};

const MenuItem = ({ item, pathname }: { item: Item; pathname: string }) => {
  const { path, items, title } = item;
  const linkRef = useRef<HTMLAnchorElement>(null);

  const containsActiveLink = useMemo(() => hasActiveLink(items, pathname), [
    path
  ]);
  const { on, toggle } = useToggle(containsActiveLink);

  const shouldShowSubMenu = Array.isArray(items) && items.length > 0;
  const external = path.startsWith("http") || item.external;

  const menu = shouldShowSubMenu ? (
    <a
      className={styles.expandable}
      ref={linkRef}
      onClick={() => {
        if (!linkRef.current) {
          return;
        }
        // reset heights of all ul tag to `auto` to avoid the heights being fixed
        // due to expandSection() call
        let node = linkRef.current.parentNode as HTMLElement;
        while (node && node.tagName !== "ASIDE") {
          node = node.parentNode as HTMLElement;
          if (node.tagName === "UL") {
            node.style.height = "auto";
          }
        }
        toggle();
      }}
    >
      {title} {on ? <MdExpandLess /> : <MdExpandMore />}
    </a>
  ) : external ? (
    <a href={path} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  ) : (
    <Link to={path} activeClassName={styles.active}>
      {title}
    </Link>
  );

  return (
    <li>
      {menu}
      {shouldShowSubMenu && (
        <SubLevelMenu on={on} items={items} pathname={pathname} />
      )}
    </li>
  );
};

const LevelTwoMenu = ({
  items,
  pathname
}: {
  items: Item[];
  pathname: string;
}) => {
  return (
    <ul className={styles.lv2}>
      {items.map((item, index) => (
        <MenuItem item={item} pathname={pathname} key={index} />
      ))}
    </ul>
  );
};

// Get reference of div container to calculate sticky nav height
function SiteNavigation(_, ref) {
  const innerRef = React.useRef<HTMLDivElement>(null);
  const activeRef = React.useRef<HTMLDivElement>(null);
  const navContext = React.useContext(NavContext);
  React.useImperativeHandle(ref, () => innerRef.current);

  React.useEffect(() => {
    activeRef.current = document.querySelector(`.${styles.active}`);
    if (activeRef.current) {
      navContext.setNavActive(activeRef);
    }
  }, []);
  return (
    <Location>
      {locationProp => {
        const { pathname } = locationProp.location;
        return (
          <div ref={innerRef} className={styles.container}>
            <ul className={styles.lv1}>
              {navigationItems.map((item, index) => (
                <li key={index}>
                  {item.items !== undefined ? (
                    <h5 className={styles.heading}>{item.title}</h5>
                  ) : (
                    <h5
                      className={styles.heading}
                      style={{ textTransform: "unset" }}
                    >
                      <Link to={item.path} activeClassName={styles.active}>
                        {item.title}
                      </Link>
                    </h5>
                  )}
                  {item.items && (
                    <LevelTwoMenu pathname={pathname} items={item.items} />
                  )}
                </li>
              ))}
            </ul>
          </div>
        );
      }}
    </Location>
  );
}

export default React.forwardRef(SiteNavigation);
