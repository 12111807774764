import * as React from "react";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import isAbsoluteURL from "is-absolute-url";

// https://github.com/ChristopherBiscardi/gatsby-mdx/issues/377
export default function Link({ href, ...props }) {
  const {
    site: { pathPrefix }
  } = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
    }
  `);

  // Only modify the link if it's coming from within the application, any other external/absolute links are left untouched
  if (!isAbsoluteURL(href)) {
    href = href.replace(pathPrefix, "");
  }

  if (isAbsoluteURL(href)) {
    return <a href={href} {...props} />;
  }

  return <GatsbyLink to={href} {...props} />;
}
