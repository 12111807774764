import * as React from "react";
import styles from "./styles.module.scss";

interface BurgerButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  active: boolean;
}

export const BurgerButton = ({ active, ...rest }: BurgerButtonProps) => {
  return (
    <button
      className={styles.button + (active ? ` ${styles.active}` : "")}
      {...rest}
    >
      <div>
        <span />
        <span />
        <span />
      </div>
    </button>
  );
};
