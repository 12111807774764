export default [
  {
    title: "Documentation",
    path: "/",
    type: "file",
    order: 1,
    external: false
  },
  {
    title: "User Guide",
    path: "/user-guide/",
    type: "directory",
    order: 1,
    items: [
      {
        title: "Get Started",
        path: "/user-guide/getting-started/",
        type: "directory",
        order: 1,
        items: [
          {
            title: "What Is Sajari?",
            path: "/user-guide/getting-started/what-is-sajari/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "How Does Sajari Work?",
            path: "/user-guide/getting-started/how-does-sajari-work/",
            type: "file",
            order: 2,
            external: false
          },
          {
            title: "Get Started in Five Minutes",
            path: "/user-guide/getting-started/get-started/",
            type: "file",
            order: 3,
            external: false
          },
          {
            title: "Essential Terminology",
            path: "/user-guide/getting-started/terminology/",
            type: "file",
            order: 4,
            external: false
          }
        ],
        external: false
      },
      {
        title: "Indexing Data",
        path: "/user-guide/indexing-data/",
        type: "directory",
        order: 2,
        items: [
          {
            title: "Overview",
            path: "/user-guide/indexing-data/overview/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Create and Manage Collections",
            path: "/user-guide/indexing-data/create-collection/",
            type: "file",
            order: 2,
            external: false
          },
          {
            title: "Crawling a Website",
            path: "/user-guide/indexing-data/crawling-a-website/",
            type: "file",
            order: 3,
            external: false
          },
          {
            title: "Advanced Crawler Configuration",
            path: "/user-guide/indexing-data/advanced-crawler/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Boost Results",
            path: "/user-guide/indexing-data/boost-results/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Exclude Results",
            path: "/user-guide/indexing-data/exclude-results/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Defining a Schema",
            path: "/user-guide/indexing-data/schema/",
            type: "file",
            order: 9999,
            external: false
          }
        ],
        external: false
      },
      {
        title: "Integrating Search",
        path: "/user-guide/integrating-search/",
        type: "directory",
        order: 2,
        items: [
          {
            title: "Getting Started",
            path: "/user-guide/integrating-search/overview/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Display Options",
            path: "/user-guide/integrating-search/display-options/",
            type: "file",
            order: 2,
            external: false
          },
          {
            title: "Filters and Sort Options",
            path: "/user-guide/integrating-search/filters/",
            type: "file",
            order: 3,
            external: false
          },
          {
            title: "Result Templates",
            path: "/user-guide/integrating-search/result-templates/",
            type: "file",
            order: 4,
            external: false
          },
          {
            title: "Search UI Integration",
            path: "/user-guide/integrating-search/widget-integration/",
            type: "file",
            order: 5,
            external: false
          },
          {
            title: "📖 How To",
            path: "/user-guide/integrating-search/guides/",
            type: "directory",
            order: 6,
            items: [
              {
                title: "Templates: Adding a Sale Banner",
                path: "/user-guide/integrating-search/guides/templates-sale/",
                type: "file",
                order: 1,
                external: false
              }
            ],
            external: false
          }
        ],
        external: false
      },
      {
        title: "Analytics",
        path: "/user-guide/analytics/",
        type: "directory",
        order: 5,
        items: [
          {
            title: "Overview",
            path: "/user-guide/analytics/overview/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Click Tracking",
            path: "/user-guide/analytics/click-tracking/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Content Issues",
            path: "/user-guide/analytics/content-issues/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Explore",
            path: "/user-guide/analytics/explore/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "PosNeg Tracking",
            path: "/user-guide/analytics/posneg-tracking/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Top Queries",
            path: "/user-guide/analytics/trends/",
            type: "file",
            order: 9999,
            external: false
          }
        ],
        external: false
      },
      {
        title: "Relevance Tuning",
        path: "/user-guide/relevance-tuning/",
        type: "directory",
        order: 5,
        items: [
          {
            title: "Understanding Relevance",
            path: "/user-guide/relevance-tuning/understanding-relevance/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Textual Relevance",
            path: "/user-guide/relevance-tuning/textual-relevance/",
            type: "file",
            order: 2,
            external: false
          },
          {
            title: "Ranking Adjustments",
            path: "/user-guide/relevance-tuning/ranking-adjustments/",
            type: "file",
            order: 3,
            external: false
          },
          {
            title: "Dynamic Boosting (AI)",
            path: "/user-guide/relevance-tuning/dynamic-boosting/",
            type: "file",
            order: 4,
            external: false
          },
          {
            title: "Redirects",
            path: "/user-guide/relevance-tuning/redirects/",
            type: "file",
            order: 4,
            external: false
          },
          {
            title: "Filter Expressions",
            path: "/user-guide/relevance-tuning/filter-expressions/",
            type: "file",
            order: 10,
            external: false
          }
        ],
        external: false
      },
      {
        title: "Optimize Search Results",
        path: "/user-guide/optimize-search-results/",
        type: "directory",
        order: 6,
        items: [
          {
            title: "Improving Content",
            path: "/user-guide/optimize-search-results/improving-content/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Preview Results",
            path: "/user-guide/optimize-search-results/preview-results/",
            type: "file",
            order: 10,
            external: false
          },
          {
            title: "Reinforcement Learning",
            path: "/user-guide/optimize-search-results/learning/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Promotions",
            path: "/user-guide/optimize-search-results/promotions/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Synonyms",
            path: "/user-guide/optimize-search-results/synonyms/",
            type: "file",
            order: 9999,
            external: false
          }
        ],
        external: false
      },
      {
        title: "Roles & Permissions",
        path: "/user-guide/roles/",
        type: "file",
        order: 6,
        external: false
      },
      {
        title: "Promoting Results",
        path: "/user-guide/promotions-merchandising/",
        type: "directory",
        order: 7,
        items: [
          {
            title: "Getting Started",
            path: "/user-guide/promotions-merchandising/overview/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Setting Up Triggers",
            path: "/user-guide/promotions-merchandising/triggers/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Manual Curation",
            path: "/user-guide/promotions-merchandising/curation/",
            type: "file",
            order: 2,
            external: false
          },
          {
            title: "Boost Rules",
            path: "/user-guide/promotions-merchandising/boosting/",
            type: "file",
            order: 3,
            external: false
          }
        ],
        external: false
      },
      {
        title: "How Sajari Works",
        path: "/user-guide/how-sajari-works/",
        type: "directory",
        order: 8,
        items: [
          {
            title: "Autocomplete",
            path: "/user-guide/how-sajari-works/autocomplete/",
            type: "file",
            order: 9999,
            external: false
          },
          {
            title: "Spelling",
            path: "/user-guide/how-sajari-works/learning/",
            type: "file",
            order: 9999,
            external: false
          }
        ],
        external: false
      }
    ],
    external: false
  },
  {
    title: "Integrations",
    path: "/integrations/",
    type: "directory",
    order: 2,
    items: [
      {
        title: "Shopify",
        path: "/integrations/shopify/",
        type: "directory",
        order: 1,
        items: [
          {
            title: "Installation",
            path: "/integrations/shopify/installation/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Theme Specific Instructions",
            path: "/integrations/shopify/theme-instructions/",
            type: "file",
            order: 2,
            external: false
          }
        ],
        external: false
      },
      {
        title: "Drupal",
        path: "/integrations/drupal/",
        type: "file",
        order: 2,
        external: false
      },
      {
        title: "Webflow",
        path: "/integrations/webflow/",
        type: "file",
        order: 2,
        external: false
      }
    ],
    external: false
  },
  {
    title: "Developer Guide",
    path: "/developer-guide/",
    type: "directory",
    items: [
      {
        title: "Get Started",
        path: "/developer-guide/get-started/",
        type: "file",
        order: 1,
        external: false
      },
      {
        title: "Search UI Widgets",
        path: "/developer-guide/widget/",
        type: "file",
        order: 4,
        external: false
      },
      {
        title: "Pipelines",
        path: "/developer-guide/pipelines/",
        type: "directory",
        order: 5,
        items: [
          {
            title: "Overview",
            path: "/developer-guide/pipelines/overview/",
            type: "file",
            order: 1,
            external: false
          },
          {
            title: "Understanding the Pipeline Templates",
            path:
              "/developer-guide/pipelines/understanding-pipeline-templates/",
            type: "file",
            order: 2,
            external: false
          },
          {
            title: "Configuring Autocomplete",
            path: "/developer-guide/pipelines/configuring-autocomplete/",
            type: "file",
            order: 3,
            external: false
          },
          {
            title: "Configuring Spelling",
            path: "/developer-guide/pipelines/configuring-spelling/",
            type: "file",
            order: 3,
            external: false
          },
          {
            title: "Variable Manipulation",
            path: "/developer-guide/pipelines/variable-manipulation/",
            type: "file",
            order: 3,
            external: false
          },
          {
            title: "Regular Expressions",
            path: "/developer-guide/pipelines/regexp/",
            type: "file",
            order: 4,
            external: false
          }
        ],
        external: false
      },
      {
        title: "API Guide",
        path: "/developer-guide/api/",
        type: "directory",
        order: 6,
        items: [
          {
            title: "API Reference",
            path: "https://docs.sajari.com/api/",
            type: "file",
            order: 1,
            external: true
          },
          {
            title: "API Client & UI Libraries",
            path: "/developer-guide/api/api-clients/",
            type: "file",
            order: 2,
            external: false
          },
          {
            title: "Aggregate Filters",
            path: "/developer-guide/api/aggregate-filters/",
            type: "file",
            order: 3,
            external: false
          },
          {
            title: "Bucket Aggregate Filters",
            path: "/developer-guide/api/bucket-filters/",
            type: "file",
            order: 4,
            external: false
          },
          {
            title: "PosNeg Tracking",
            path: "/developer-guide/api/posneg-tracking/",
            type: "file",
            order: 5,
            external: false
          }
        ],
        external: false
      }
    ],
    external: false
  }
];
