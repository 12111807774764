import * as React from "react";
import { MDXProvider } from "@mdx-js/react";
import "../styles/global.scss";
import Helmet from "react-helmet";
import "normalize.css";

import Header from "../components/header";
import Footer from "../components/footer";
import { SiteNavigation } from "../components/SiteNavigation";

import styles from "./style.module.scss";
import MobileNavContext from "../context/MobileNavContext";
import useToggle from "../hooks/useToggle";

import Link from "../components/link";
import { CodeTabProvider } from "../context/CodeTabContext";
import NavContext from "../context/NavContext";
import { Location } from "@reach/router";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    speed: 400,
    durationMax: 400,
    speedAsDuration: false
  });
}

const mdxComponents = {
  Link,
  a: Link,

  wrapper: props => (
    <CodeTabProvider>
      <main {...props} />
    </CodeTabProvider>
  )
};

export interface LayoutProps {
  noSearch?: boolean;
  children?: (
    callbackRef: (ref: React.MutableRefObject<HTMLElement>) => void
  ) => React.ReactNode;
}

const NAV_MARGIN = 26;
const FEEDBACK_FORM_URL = `https://docs.google.com/forms/d/e/1FAIpQLScvHROAqsMGqH2nu-qjB6XmYcBMH8lvGPbo48UINwdExniVxQ/viewform?usp=pp_url&entry.1317721609=`;

export default function Layout({ children, noSearch = false }: LayoutProps) {
  let offset = 0;
  const state = useToggle(false);

  const headerRef = React.useRef<HTMLElement>();
  const footerRef = React.useRef<HTMLElement>();
  const navRef = React.useRef<HTMLElement>();
  let tocRef = React.useRef<HTMLElement>();

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", calcMaxNavHeight);
    calcMaxNavHeight();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", calcMaxNavHeight);
    };
  }, []);

  const calcMaxNavHeight = () => {
    if (!footerRef.current || !headerRef.current) {
      return;
    }

    const footerRect = footerRef.current.getBoundingClientRect();
    const headerRect = headerRef.current.getBoundingClientRect();

    if (headerRect.top + headerRect.height >= 0) {
      const offset =
        window.innerHeight -
        (headerRect.top + headerRect.height) -
        NAV_MARGIN * 2.5;

      navRef.current.style.maxHeight = `${offset}px`;
    } else if (window.innerHeight + footerRect.height >= footerRect.bottom) {
      const offset = footerRect.bottom - footerRect.height - NAV_MARGIN * 2.5;
      navRef.current.style.maxHeight = `${offset}px`;
    } else {
      navRef.current.style.maxHeight = `${window.innerHeight}px`;
    }
  };

  const handleScroll = () => {
    if (!window && window.innerWidth > 768) {
      return;
    }

    function calcMaxNavHeight() {
      const rect = footerRef.current.getBoundingClientRect();
      if (window.innerHeight + rect.height >= rect.bottom) {
        offset = rect.bottom - rect.height - NAV_MARGIN * 2.5;
        navRef.current.style.maxHeight = `${offset}px`;
        if (tocRef.current) {
          tocRef.current.style.maxHeight = `${offset}px`;
        }
      }
    }

    if (typeof window.requestAnimationFrame === "function") {
      window.requestAnimationFrame(calcMaxNavHeight);
    } else {
      calcMaxNavHeight();
    }
  };

  const handleScrollActiveMenu = (
    activeRef: React.MutableRefObject<HTMLElement>
  ) => {
    setTimeout(() => {
      if (activeRef.current) {
        navRef.current.scrollTop = activeRef.current.offsetTop;
      }
    });
  };
  const callbackRef = (ref: React.MutableRefObject<HTMLElement>) => {
    tocRef = ref;
  };

  return (
    <MobileNavContext.Provider value={state}>
      <NavContext.Provider value={{ setNavActive: handleScrollActiveMenu }}>
        <Helmet>
          <link
            href="//fonts.googleapis.com/css?family=Fjalla+One"
            rel="stylesheet"
            type="text/css"
          />
        </Helmet>
        <Header ref={headerRef} noSearch={noSearch} />

        <div className={styles.container}>
          <aside
            className={
              styles.navigation + (state.on ? ` ${styles.mobileNavOpen}` : "")
            }
          >
            <SiteNavigation ref={navRef} />
          </aside>
          <main className={styles.main}>
            <div className={styles.wrong}>
              <Location>
                {locationProps => (
                  <a
                    href={`${FEEDBACK_FORM_URL}${locationProps.location.href}`}
                    target="_blank"
                    rel="noopener"
                  >
                    Found something wrong or missing?
                  </a>
                )}
              </Location>
            </div>
            <MDXProvider components={mdxComponents}>
              <div className={styles.body}>
                {children !== undefined && typeof children === "function"
                  ? children(callbackRef)
                  : children}
              </div>
            </MDXProvider>
          </main>
        </div>

        <Footer ref={footerRef} />
      </NavContext.Provider>
    </MobileNavContext.Provider>
  );
}
