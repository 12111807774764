import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";

import styles from "./styles.module.scss";
import LogoIcon from "../svg/LogoIcon";
import { BurgerButton } from "../burgerButton/BurgerButton";
import MobileNavContext from "../../context/MobileNavContext";
import Loadable from "react-loadable";

const LoadableSearch = Loadable({
  loader: () => import("../search/Search"),
  loading: () => null
});

export interface HeaderProps {
  noSearch?: boolean;
}

function Header({ noSearch = false }, ref) {
  const { on, set } = useContext(MobileNavContext);

  const innerRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle(ref, () => innerRef.current);

  const handleToggle = () => {
    if (on) {
      document.documentElement.classList.remove("disable-scroll");
      set(false);
    } else {
      document.documentElement.classList.add("disable-scroll");
      set(true);
    }
  };

  useEffect(() => {
    return () => {
      set(false);
      document.documentElement.classList.remove("disable-scroll");
    };
  }, []);

  return (
    <header ref={innerRef} className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.leftWrapper}>
            <Link className={styles.logo} to="/">
              <LogoIcon />
              <span>Docs</span>
            </Link>
            {!noSearch && <div className={styles.pipe} />}
            {!noSearch && <LoadableSearch />}
          </div>
          <BurgerButton onClick={handleToggle} active={on} />
        </div>
        <div className={styles.right}>
          <a href="https://www.sajari.com/">Back to Sajari Homepage</a>
          <div className={styles.pipe} />
          <a
            href="https://app.sajari.com/sign-in"
            target="_blank"
            rel="noopener noreferrer"
          >
            Log in
          </a>
          <a
            href="https://app.sajari.com/sign-up"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up
          </a>
        </div>
      </div>
    </header>
  );
}

export default React.forwardRef(Header);
