import * as React from "react";
import Link from "../link";
import styles from "./styles.module.scss";
import { getClassName } from "../utils";
import NewsletterSignUp from "./newsletterSignUp";

function Footer(_, ref) {
  const innerRef = React.useRef<HTMLElement>();
  React.useImperativeHandle(ref, () => innerRef.current);
  return (
    <footer ref={innerRef} className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div>
            <SectionHeading>Solutions</SectionHeading>
            <List>
              <li>
                <FooterLink href="https://www.sajari.com/solutions/site-search">
                  Site Search
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/solutions/ecommerce-search">
                  E-commerce Search
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/solutions/google-search-appliance-replacement">
                  Replace GSA
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/solutions/google-site-search-alternative">
                  Replace GSS
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/solutions/google-custom-search-engine-alternative">
                  Replace GCSE
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/pricing">
                  Pricing
                </FooterLink>
              </li>
            </List>
          </div>
          <div>
            <SectionHeading>Resources</SectionHeading>
            <List>
              <li>
                <FooterLink href="https://www.sajari.com/resources/page-debug">
                  Page debug
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/resources/site-search-health-report">
                  Search Health Report
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/resources/google-tag-manager-install">
                  GTM install
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/resources/demos">
                  Demos
                </FooterLink>
              </li>
            </List>
          </div>
          <div>
            <SectionHeading>Company</SectionHeading>
            <List>
              <li>
                <FooterLink href="https://www.sajari.com/company/about-us">
                  About us
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/company/contact">
                  Contact us
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/blog">Blog</FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/company/careers">
                  Careers
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/privacy">
                  Privacy
                </FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/terms">
                  Terms
                </FooterLink>
              </li>
            </List>
          </div>
          <div>
            <SectionHeading>Support</SectionHeading>
            <List>
              <li>
                <FooterLink href="/">Docs</FooterLink>
              </li>
              <li>
                <FooterLink href="https://www.sajari.com/faq">FAQ</FooterLink>
              </li>
            </List>
          </div>
        </div>
        <div className={styles.right}>
          <p className="left" style={{ marginBottom: 26 }}>
            Sign up for the newsletter and we'll inform you of updates, offers
            and more.
          </p>
          <NewsletterSignUp />
        </div>
      </div>
      <p
        className="text-small"
        style={{
          marginBottom: 0,
          marginTop: 30,
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        Copyright &copy; {new Date().getFullYear()}. Sajari Pty Ltd
      </p>
    </footer>
  );
}

export default React.forwardRef(Footer);

function List({ children, ...props }: React.HTMLProps<HTMLUListElement>) {
  const className = getClassName(props, styles.list);
  return <ul className={className.join(" ")}>{children}</ul>;
}

function SectionHeading({
  children,
  ...props
}: React.HTMLProps<HTMLHeadingElement>) {
  const className = getClassName(props);
  return <h3 className={className.join(" ")}>{children}</h3>;
}

function FooterLink({
  href,
  children
}: React.PropsWithChildren<{ href: string }>) {
  return (
    <Link className={styles.link} href={href}>
      {children}
    </Link>
  );
}
